.results-history-table-header {
  border-radius: 12px;
  width: 100%;
  background-color: #e0f0ce;
  padding: 16px;
}

.results-history-table-row {
  background-color: #eff0ef;
  width: 100%;
  height: 5vh;
}

.centre-item {
  vertical-align: middle;
}

.header-vertical-top {
  width: 10%;
  vertical-align: top;
}

.hide {
  display: none;
}

.accordion-item {
  padding: 0 !important;
  border-radius: 20px;
}

.result-accordian-width {
  width: 100% !important;
}

table td, table th {
  text-align: center;
  font-weight: 600;
  width: 10%;
}

.header-th-left {
  text-align: left;
  /* padding: 20px; */
}

.task-score {
  vertical-align: middle;
}

.reading-score {
  vertical-align: middle;
}

.task-badge-parent span {
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  width: 5vw;
}

.header-size-results {
  width: 15vh;
}

.align-left {
  text-align: left;
}

.align-centre {
  text-align: center;
}

.centre-student-items {
  vertical-align: middle;
}

.flag-size-results {
  vertical-align: middle;
  text-align: center;
  width: 40px;
}

.low-color {
  color: white;
  background-color: red !important;
  border: 1px solid red;
}

/* .med-color {
    color: white;
    background-color: orange !important;
    border: 1px solid orange;
} */

.med-color {
  background-color: rgb(217, 217, 48) !important;
  color: black;
  border: 1px solid rgb(217, 217, 48);
}

.noRisk-color {
  background-color: white !important;
  color: black;
  border: 1px solid rgb(22, 151, 27);
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sort-icon {
  width: 20px;
  margin-top: 5px; 
}


/* Hacky way to fix print issue on individualStudentResults */
/* @media print {
  .landscape-print {
    page: landscape;
  }

  .results-history-table thead {
    display: none !important;
  }

  .print-only-header {
    display: block !important; 
   }

   .results-history-table th, .results-history-table td {
    width: 15vh;
  }
}

.print-only-header {
  display: none;
} */

@media print {
  .landscape-print {
    page: landscape;
  }

  .results-history-table {
    width: 100% !important;
    table-layout: fixed;
    border-collapse: collapse;
    display: block !important;
  }

  /* .print-only-header {
    visibility: visible !important;
    height: auto !important;
    opacity: 1 !important;
  } */
/* 
  .result-history-table-rows tr {
    page-break-before: always;
  } */

  .detailed-view-true tr {
    page-break-before: always;
  } 

  .results-history-table th, .results-history-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }

  .screen-only-header {
    visibility: hidden !important;
    height: 0 !important;
    opacity: 0 !important;
  }
}

.print-only-header {
  visibility: hidden;
  height: 0;
  opacity: 0;
}

.table-responsive-wrapper {
  overflow-x: auto;
  width: 100%;
}

/* Customize the entire scrollbar */
.table-responsive-wrapper::-webkit-scrollbar {
  height: 4vh; /* Adjust the thickness of the scrollbar */
}

/* Scrollbar track */
.table-responsive-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1; /* Lighter color for the track */
  border-radius: 6px; /* Rounded corners */
}

/* Scrollbar handle */
.table-responsive-wrapper::-webkit-scrollbar-thumb {
  background-color: rgb(44, 118, 147, 1); /* Color for the handle */
  border-radius: 6px; /* Rounded corners */
  border: 2px solid #f1f1f1; /* Adds padding and contrast around the handle */
}

/* Handle on hover */
.table-responsive-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: rgb(35, 94, 118);
}


.delete-button {
  background-color: transparent;
  border: none;
  color: red;
  font-size: 1.5em; /* Increase size */
  font-weight: bold;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%; /* Circular background */
  background-color: rgba(255, 0, 0, 0.3); /* Light red background */
  transition: background-color 0.3s, color 0.3s;
}

.delete-button:hover {
  background-color: rgba(255, 0, 0, 0.6); /* Darker red background on hover */
  color: darkred;
}

.other-answer-option-width{
  width: 100% !important;

}